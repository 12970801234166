import React, { useState, useRef } from "react";
import { isMobile } from "react-device-detect";
import { isIOS } from "react-device-detect";
import BetterLightbox from "./BetterLightbox";
import "./BetterLightbox.css";
import placeholderImage from "./take-photo.jpg";
import loaderImage from "./search-1.gif";
import JSZip from "jszip";

function ImageConverter() {
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [imagePreview, setImagePreview] = useState(placeholderImage);
  const [isImageSelected, setIsImageSelected] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const fileInputRef = useRef(null);
  // const imagePath = "https://wcudaipur23-public.s3.ap-south-1.amazonaws.com/";
  const imagePath = "https://nasya.showmy.pics/images/";

  const [showDownloadButton, setShowDownloadButton] = useState(false);

  const handleFileChange = (event) => {
    
    
    const file = event?.target?.files[0];

    
    if (file && ["image/jpeg", "image/png"].includes(file.type)) {
      setImagePreview(URL.createObjectURL(file));
      setIsImageSelected(true);
      setErrorMessage("");
    } else {
      setErrorMessage("Please select a JPG, JPEG, or PNG file.");
      resetFileInput();
    }
  };

  const resetFileInput = () => {
    setTimeout(() => {}, 300);
    setImagePreview(placeholderImage);
    setIsImageSelected(false);
    fileInputRef.current.value = "";
  };

  const openModal = (imageSrc, index) => {
    setCurrentIndex(index);
    setModalOpen(true);
  };

  const handleDownloadAll = async () => {
    try {
      const zip = new JSZip();

      await Promise.all(
        images.map(async (image, index) => {
          try {
            const response = await fetch(image.url);

            if (!response.ok) {
              throw new Error(`Failed to fetch image ${index + 1}`);
            }

            const blob = await response.blob();

            // Log the loaded image
            console.log(`Loaded image ${index + 1}:`, blob);

            zip.file(`image_${index + 1}.jpg`, blob);
          } catch (error) {
            console.error(error.message);
          }
        })
      );

      const zipBlob = await zip.generateAsync({ type: "blob" });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(zipBlob);
      link.download = "all_images.zip";
      link.click();
    } catch (error) {
      console.error(error.message);
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const file = fileInputRef.current.files[0];
    if (!file) {
      setErrorMessage("Please select a file to upload.");
      setIsLoading(false);
      resetFileInput();
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      const img = new Image();
      img.src = reader.result;

      img.onload = async () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);

        const reducedQualityImage = canvas.toDataURL("image/jpeg", 0.5);

        try {
          const response = await fetch(
            "https://xh38cnfz7e.execute-api.ap-south-1.amazonaws.com/default/nasya-facefind",
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                image_bytes: reducedQualityImage.split("base64,")[1],
              }),
              redirect: "follow",
            }
          );

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          const data = await response.json();

          if (data && data.length > 0) {
            setImages(
              data.map((imageName) => ({
                url: imagePath + imageName,
                alt: imageName,
              }))
            );
            setShowDownloadButton(true);
          } else {
            setErrorMessage("No Photo Found. Please try again.");
          }
        } catch (error) {
          console.error("Error:", error);
          setErrorMessage(
            "An error occurred while fetching your images. Please try again."
          );
        } finally {
          setIsLoading(false);
        }
      };
    };
    reader.readAsDataURL(file);
  };

  // const ImagePreview = () => {
  //   return (
  //     // <figure
  //     //   className="block w-full text-center cursor-pointer "
  //     // >
  //       <img
  //         src={imagePreview}
  //         alt="Preview"
  //         className="inline-block h-48 w-auto object-cover rounded-full border-4 border-gray-300 shadow-lg"
  //         onClick={() => {
  //           fileInputRef?.current?.click();
  //         }}
  //       />
  //     // </figure>
  //   );
  // };

  const buttonText = isMobile
    ? "Scan your face to find your photos"
    : "Upload your face image to find your photos";

  const InfoModal = () => (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-75 flex items-center justify-center z-50 transition-opacity duration-500 ease-in-out">
      <div className="relative bg-white p-8 rounded-lg shadow-xl w-full max-w-lg">
        <button
          className="absolute top-0 right-0 mt-4 mr-4 text-2xl font-semibold text-gray-800 hover:text-gray-600 transition-all duration-300 ease-in-out focus:outline-none"
          onClick={() => setIsInfoModalOpen(false)}
        >
          &times;
        </button>
        <h2 className="text-xl font-bold mb-6">How It Works</h2>
        <p className="mb-6">
          Our AI-powered face recognition system will compare your uploaded
          photo with images from the event. Just upload a clear, front-facing
          photo of yourself, and we'll find all the event photos that include
          you!
        </p>
        <ol className="list-decimal list-inside space-y-3">
          <li>Upload your photo.</li>
          <li>Our AI will analyze the image.</li>
          <li>View and download your photos!</li>
        </ol>
      </div>
    </div>
  );

  const closeModal = () => {
    setModalOpen(false);
    // setCurrentIndex(null);
  };

  return (
    <div className="container mx-auto p-6 bg-white shadow rounded-lg">
      <div className="flex items-center justify-between p-4 bg-gray-100 rounded-lg shadow transition-all duration-300 ease-in-out hover:shadow-md">
        <div className="flex flex-col">
          <p className="text-lg text-gray-800 font-semibold">{buttonText}</p>

          {!isIOS ? (
            <small className="text-gray-600">
              Click on Image below to start
            </small>
          ) : null}
        </div>
        <button
          className="flex items-center justify-center w-12 h-12 rounded-full bg-blue-200 text-blue-600 shadow hover:shadow-md hover:bg-blue-300 transition ease-in-out duration-300 focus:outline-none"
          onClick={() => setIsInfoModalOpen(true)}
        >
          <span className="sr-only">Information</span>ℹ
        </button>
      </div>
      <form id="imageForm" onSubmit={handleSubmit} className="space-y-6 mt-12 block w-full text-center cursor-pointer">
        {!isIOS ? (
          <label htmlFor="myinput" style={{ cursor: "pointer" }}>
            <img
              src={imagePreview}
              alt="Preview"
              className="inline-block h-48 w-auto object-cover rounded-full border-4 border-gray-300 shadow-lg"
            />
          </label>
        ) : null }

      <input
          ref={fileInputRef}
          type="file"
          accept="image/jpeg, image/png"
          capture="user"
          onChange={handleFileChange}
          className={!isIOS?'hidden':'block w-full text-lg text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400'}
          id="myinput"
        /> 



        {isImageSelected && (
          <div className="flex justify-center">
            <button
              type="submit"
              className="bg-green-600 hover:bg-green-700 text-white font-bold py-3 px-6 rounded-lg shadow-lg transition ease-in-out duration-300 hover:scale-95 w-80"
            >
              Show My Pics
            </button>
          </div>
        )}

        {showDownloadButton && (
          <div className="flex justify-end">
            <button
              type="button"
              onClick={handleDownloadAll}
              className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-lg shadow-lg transition ease-in-out duration-300 hover:scale-95 w-50"
            >
              Download All Images
            </button>
          </div>
        )}
      </form>

      {isLoading && (
        <div className="flex justify-center items-center">
          <img src={loaderImage} alt="Loading..." className="w-16 h-16 m-10" />
        </div>
      )}
      {errorMessage && !isLoading && (
        <div className="p-4 mb-4 text-base text-red-800 bg-red-200 rounded-lg shadow">
          {errorMessage}
        </div>
      )}

      <div
        id="response"
        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-6"
      >
        {images.map((image, index) => (
          <div
            className="relative w-full h-48 rounded-lg overflow-hidden cursor-pointer"
            key={image.alt + "_" + index}
            onClick={() => openModal(image.url, index)}
          >
            <img
              src={image.url}
              alt={image.alt}
              className="object-cover w-full h-full transition duration-300 ease-in-out hover:opacity-75"
              loading="lazy"
              onError={(e) => {
                e.target.src = placeholderImage;
              }}
            />
            <div className="absolute inset-0 bg-black bg-opacity-10 transition duration-300 ease-in-out hover:bg-opacity-20"></div>
          </div>
        ))}
      </div>

      {isInfoModalOpen && <InfoModal />}

      {modalOpen && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen">
            <div className="fixed inset-0 ">
              {modalOpen && ( // Use modalOpen directly
                <BetterLightbox
                  images={images} // Pass the entire images array if required by BetterLightbox
                  startIndex={currentIndex} // Use currentIndex as the starting index
                  onClose={closeModal}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ImageConverter;
